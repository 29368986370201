import { FontAwesome5 } from '@expo/vector-icons'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { Pressable } from 'react-native'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  assets,
  AuthService,
  downloadURI,
  ErrorHandling,
  Form,
  GeneralService,
  ImgUploadMutiple,
  Permission,
  PulseOpsFormat,
  Select,
  SelectSearch,
  SelectTable,
  sharedStyle,
  StorageBlob,
  SupplementaryInfo,
  SupplementaryInfoService,
  useMobile,
  StorageBlobApi,
  TaskType,
  ModalComponent,
  formatNumberWithComma,
  Alert,
  CashLessService,
  SubmissionService,
  PulseOpsApi,
  TaskDetailService,
  Customer,
  CustomedDateTimePicker,
  PulseOpsService,
  TaskDetail,
  SubClaimTypeBenefit,
  SubClaimType,
  HospitalType,
  VerClaimType,
  CriticalIllnessType,
  ICDType,
  ClaimTypeInfo,
  TransactionLabel,
  TransactionType,
  InternalFeedbackService
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { Controller, FieldError, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { ImageDialog } from '../related-info'
import * as SupplementaryInfoForm from './SupplementaryInfoForm'
import * as A from 'fp-ts/Array'
import { differenceWith, isEqual } from 'lodash'
import { TaskDetailStackParamList } from '../../TaskDetailStackParamList'
import { StackNavigationProp } from '@react-navigation/stack'
import { requestRefreshAllInDashboard } from '@pulseops/task/common'
import styled from 'styled-components/native'
import _ from 'lodash'
import { GroupCode } from './SupplementaryInfoForm'
import moment from 'moment'
import i18next from 'i18next'
import * as O from 'fp-ts/lib/Option'
import { useIsFocused } from '@react-navigation/native'
const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF',
    borderColor: '#D3DCE6'
  },
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D3DCE6'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D3DCE6'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D3DCE6'
    }
  }
})

enum TabId {
  SuspendHistory,
  GeneralLetter
}

type Tab = {
  id: TabId
  title: string
  element: () => JSX.Element
}

interface GroupCodeType {
  code: string
  nameEn: string
  nameVi: string
}

interface RelatedDocRequestForm {
  url: string
  docType: string
  docId: string
  mainDoc: string
  subDoc: string
  batchNum: string
  source: string
}

interface FormError {
  isPreviewed: boolean
}
const currentDate = new Date()

const PopupViewSuspend = (props: { onClose: () => void; open: boolean; id?: string; modifiedDate: string }) => {
  const { onClose, open, id, modifiedDate } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const [listReceipt, setListReceipt] = React.useState<
    | {
      receiptNo: string
      receiptAmount: number
      updateToCoreDate: string
    }[]
    | undefined
  >([])

  const handleClose = () => {
    setListReceipt([])
    onClose()
  }
  React.useEffect(() => {
    if (!_.isEmpty(id)) {
      pipe(
        SupplementaryInfoService.searchBySupplementaryId(id || '', modifiedDate || ''),
        ZIO.map((res) =>
          setListReceipt(
            res.receiptHistory?.map((item) => ({
              receiptNo: item.receiptNo || '-',
              receiptAmount: item.transactions?.reduce<number>((pre, cur) => pre + cur.amount, 0) || 0,
              updateToCoreDate: item.createdDate || '-'
            }))
          )
        ),
        ErrorHandling.run()
      )
    }
  }, [id])
  return (
    <ModalComponent
      title={t('SupplementaryInfo:SuspendHistory')}
      visible={open}
      onClose={handleClose}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        }
      ]}
    >
      <View style={{ padding: 10 }}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>{t('Cashless:receiptNo')}</TableCell>
              <TableCell>{t('Cashless:receiptAmount')}</TableCell>
              <TableCell>{t('Cashless:updateToCoreDate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listReceipt?.map((item, index) => {
              return (
                <TableRow>
                  <TableCell>{item.receiptNo}</TableCell>
                  <TableCell>{formatNumberWithComma(item.receiptAmount)}</TableCell>
                  <TableCell>{item.updateToCoreDate}</TableCell>
                </TableRow>
              )
            })}
            {_.isEmpty(listReceipt) && (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  {t('common:noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </View>
    </ModalComponent>
  )
}

export const SupplementaryInfoTab = (props: {
  policyNum: string
  processId: string
  caseId: string
  roles: string[]
  readonly: boolean
  disable?: boolean
  active: {
    value: boolean
    setSupIsActive: (val: boolean) => void
  }
  isClaim: boolean
  category: string
  officeCode?: string
  writeOffAction: boolean
  onHandleActionSuccess?: (mustRefreshTaskGroup?: boolean) => void
  transaction: string
  detail: TaskDetail.Type
  claimData: {
    accidents: VerClaimType[] | null
    claimTypes?: SubClaimType[] | null
    subClaimTypes?: SubClaimTypeBenefit[] | null
    tpdTypes?: VerClaimType[] | null
    ciTypes?: CriticalIllnessType[] | null
    icdTypes: ICDType[] | null
    hospitals: HospitalType[] | null
    id: string | null
  }
  firstSuspendDate?: string | null
}) => {
  const { disable = false, onHandleActionSuccess, detail } = props
  const { isWide } = useMobile()
  const classes = useStyles()
  const form = useForm<SupplementaryInfoForm.Form>()
  const errorForm = useForm<FormError>()
  const register = Form.register(form)

  const { control, getValues, setValue } = useForm<{
    data: SupplementaryInfoForm.History[]
  }>()
  const showColumnTimeout =
    props.category === TaskType.PolicyService ||
    props.category === TaskType.Complaint ||
    props.category === TaskType.CustomerService ||
    props.category === TaskType.IDC
  const formTableArray = useFieldArray({
    control,
    name: 'data'
  })

  const docIdPendingLetter = '10409031'
  const casePS = props.category === TaskType.PolicyService
  const showGeneralInfo = [TaskType.PolicyService, TaskType.Claim].includes(props.category as TaskType)
  const formErrors = form.formState.errors

  const pendingLetterControl = register('pendingLetter')

  const { t, i18n } = useTranslation()
  const t2 = i18next.getFixedT('vi')
  const isFocused = useIsFocused()

  const { getFooter, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [selected, setSelected] = React.useState<Tab | null>(null)
  // const [statusOption, setStatusOption] = React.useState<SupplementaryInfoForm.SelectOptions[]>([])
  const [allowPendingLetter, setAllowPendingLetter] = React.useState<boolean>(false)
  const [openPopup, setOpen] = React.useState<boolean>(false)
  const [openPopupViewSuspend, setOpenPopupViewSuspend] = React.useState<boolean>(false)
  const [supplementaryId, setSupplementaryId] = React.useState<{ id: string; modifiedDate: string }>({
    id: '',
    modifiedDate: ''
  })
  const [groupCodeList, setGroupCodeList] = React.useState<GroupCodeType[]>([])
  const [image, setImage] = React.useState<string[]>([])
  const [pendingLetters, setPendingLetters] = React.useState<string>('')

  const [info, setInfo] = React.useState<{
    currentUser: string
    historyList: SupplementaryInfo.HistoryList
  } | null>(null)
  const [dataDoc, setDataDoc] = React.useState<RelatedDocRequestForm[]>([])
  const [dataGeneral, setDataGeneral] = React.useState<SupplementaryInfo.GeneralInfoList | null>(null)
  const suspendGroupExclude: string[] = [
    SupplementaryInfoForm.GroupCode.AML2,
    SupplementaryInfoForm.GroupCode.SYS1,
    SupplementaryInfoForm.GroupCode.PAY1
  ]
  const [dataGroupCode, setDatadGroupCode] = React.useState<{
    suspendGroup: string
    suspendGroupVN: string
    unsuspendDate: string
  } | null>(null)
  const [loading, bindLoader] = useLoading()
  const [IDC_MAXDATE, setIDC_MAXDATE] = React.useState<Date>()

  const allowEdit =
    (props.roles.includes(Permission.EditSupplementaryInfoTaskDetail) ||
      props.roles.includes(Permission.EditSupplementaryInfoPC) ||
      props.roles.includes(Permission.EditSupplementaryInfoDS) ||
      props.roles.includes(Permission.EditSupplementaryInfoIDCTaskDetail)) &&
    !props.readonly

  const suspendWriteOff =
    props.roles.includes(Permission.SuspendWriteOffPCTaskList) && props.readonly && props.writeOffAction

  const detailPayload: TaskDetail.VerificationClaim = React.useMemo(
    () => props.detail?.payload as TaskDetail.VerificationClaim,
    [props.detail]
  )

  const listClaim: ClaimTypeInfo[] = pipe(TaskType.Claim === props.category ? GeneralService.getClaims : ZIO.succeed([]), ErrorHandling.runDidMount([])) || []

  const claimType = props?.claimData?.claimTypes?.find((elm) => elm.claimTypeCode === detailPayload?.claimType) ?? null
  const getStatusCaseCL =
    props.category === TaskType.Claim
      ? pipe(InternalFeedbackService.getCaseStatusByCaseID(`CL-${props.claimData.id}`), ErrorHandling.runDidMount([]))
      : null

  const translate = (en: string, vi: string) => (i18n.language === 'en' ? en : vi)
  const getDate = (date: string) => PulseOpsFormat.datetoFormat(new Date(date), 'DD/MM/yyyy')

  const getStatusTranslator = (status: string) => {
    switch (status) {
      case SupplementaryInfoForm.Status.ACTIVE:
        return t('Status:Active')
      case SupplementaryInfoForm.Status.INACTIVE:
        return t('Status:Inactive')
      case SupplementaryInfoForm.Status.COMPLETE:
        return t('Status:Complete')
      default:
        return t('Status:Incomplete')
    }
  }

  const [typeList, setTypes] = React.useState<SupplementaryInfoForm.SelectOptions>([])
  const [letterAndTypes, setLetterAndTypes] = React.useState<SupplementaryInfoForm.SelectOptionsSuspendType>([])

  const statuses = [
    { value: SupplementaryInfoForm.Status.ACTIVE, label: t('Status:Active') },
    { value: SupplementaryInfoForm.Status.INACTIVE, label: t('Status:Inactive') }
  ]
  const suppendHistoryStatus: SupplementaryInfoForm.SelectOptions = [
    { value: SupplementaryInfoForm.Status.ACTIVE, label: t('Status:Active') },
    { value: SupplementaryInfoForm.Status.INACTIVE, label: t('Status:Inactive') },
    { value: SupplementaryInfoForm.Status.COMPLETE, label: t('Status:Complete') },
    { value: SupplementaryInfoForm.Status.INCOMPLETE, label: t('Status:Incomplete') }
  ]

  const imgExtensions = ['.png', '.jpg', '.jpeg']
  const suspendGroupApproved: string[] = [
    SupplementaryInfoForm.GroupCode.DOC1,
    SupplementaryInfoForm.GroupCode.DOC2,
    SupplementaryInfoForm.GroupCode.PRE1
    // SupplementaryInfoForm.GroupCode.DOC3
  ]

  const suspendGroupApprovedClaim: string[] = [
    SupplementaryInfoForm.GroupCode.DOC4,
    // SupplementaryInfoForm.GroupCode.PAY3,
    SupplementaryInfoForm.GroupCode.DOC5
  ]

  // const IDC_MAXDATE = props.category === TaskType.IDC ?
  //   pipe(
  //     props.firstSuspendDate,
  //     O.fromNullable,
  //     O.fold(
  //       () => moment(new Date()).add(12, 'days').toDate(),
  //       (itemData) => {
  //         const customedMaxDay = moment(itemData).add(20, 'days').toDate()
  //         return customedMaxDay
  //       }
  //     )
  //   ) : undefined

  const updatedData = (list: SupplementaryInfoForm.History[]) => {
    return list.length > 0 && info && info.historyList.data.length > 0
      ? info.historyList.data
        .filter(({ status }, index) => {
          const oldStatus = list.length > index ? list[index].status.value : ''
          return status !== oldStatus
        })
        .map(({ id, pendingLetters, suspendCode, suspendGroupCode, expiredDate }) => {
          const updatedItem = list.find((_) => _.suspendCode === suspendCode)
          return {
            id: id ?? '',
            pendingLetters: pendingLetters?.map(({ azureBlobName }) => azureBlobName ?? '') ?? [],
            status: updatedItem?.status ?? {
              value: '-',
              label: '-'
            },
            suspendCode: suspendCode ?? '',
            suspendGroup: suspendGroupCode ?? '',
            user: info.currentUser,
            expiredDate: expiredDate ? new Date(expiredDate) : undefined
          }
        })
      : []
  }

  const updateTimeout = (list: SupplementaryInfoForm.History[]) => {
    return list.length > 0 && info && info.historyList.data.length > 0
      ? info.historyList.data
        .filter(({ expiredDate }, index) => {
          const oldExpiredDate = list.length > index ? list[index].expiredDate : ''
          return expiredDate !== oldExpiredDate
        })
        .map(({ id, pendingLetters, suspendCode, status }) => {
          const updatedItem = list.find(
            (_) => _.suspendCode === suspendCode && (_.status.value === status || _.expiredDate)
          )
          return {
            id: id ?? '',
            pendingLetters: pendingLetters?.map(({ azureBlobName }) => azureBlobName ?? '') ?? [],
            status: {
              value: status,
              label: '-'
            },
            suspendCode: suspendCode ?? '',
            user: info.currentUser,
            expiredDate: updatedItem?.expiredDate
          }
        })
      : []
  }

  const initSuspendHistory = (data: SupplementaryInfo.History[]) => {
    // formTableArray.remove()
    const formData: any[] = []
    data.forEach(
      ({
        id,
        suspendGroupCode,
        suspendGroupEN,
        suspendGroupVN,
        suspendType,
        suspendTypeVN,
        status,
        createdDate,
        createdBy,
        modifiedBy,
        modifiedDate,
        pendingLetters,
        suspendCode,
        expiredDate,
        receivedDate,
        addLackDocs
      }) => {
        formData.push({
          id: id ?? '',
          idHistory: id ?? '',
          suspendGroupCode: suspendGroupCode ?? '',
          suspendGroup: translate(suspendGroupEN, suspendGroupVN),
          suspendType: translate(suspendType, suspendTypeVN),
          pendingLetters: pendingLetters ?? [],
          status: {
            value: status || '-',
            label: getStatusTranslator(status ?? '') || '-'
          },
          createdUser: createdBy ?? '-',
          createdDate: createdDate ? getDate(createdDate) : '-',
          updatedUser: modifiedBy ?? '-',
          updatedDate: modifiedDate ? getDate(modifiedDate) : '-',
          suspendCode: suspendCode,
          expiredDate: expiredDate,
          receivedDate: receivedDate ? getDate(receivedDate) : '-',
          addLackDocs: addLackDocs ?? []
        })
      }
    )
    setValue('data', formData)
  }

  const updateSuppendHistory = () => {
    return pipe(
      SupplementaryInfoService.getHistoryListAndUsers(props.processId),
      ZIO.map((res) => {
        setInfo(res)
        const { historyList } = res
        const { data } = historyList

        initSuspendHistory([...data])
        props.active.setSupIsActive(
          historyList.data.some(({ status }) => status === SupplementaryInfoForm.Status.ACTIVE)
        )
        // setStatusOption(
        //   data.map(({ status }) => {
        //     switch (status) {
        //       case SupplementaryInfoForm.Status.ACTIVE:
        //         return suppendHistoryStatus
        //       case SupplementaryInfoForm.Status.INACTIVE:
        //         return [suppendHistoryStatus[1]]
        //       case SupplementaryInfoForm.Status.COMPLETE:
        //         return [suppendHistoryStatus[2]]
        //       case SupplementaryInfoForm.Status.INCOMPLETE:
        //         return [suppendHistoryStatus[3]]
        //       default:
        //         return []
        //     }
        //   })
        // )
        //   this.initialTable()
        return undefined
      })
    )
  }

  const updateGeneralInfoDoc = () => {
    pipe(
      TaskDetailService.GetRelatedDoc(props.processId),
      ZIO.map((x) => ({
        azureDocuments: x.azureDocuments || [],
        archivedDocuments: x.archivedDocuments || []
      })),
      ZIO.map((res) => [
        ...res.azureDocuments.map((x) => {
          const docType = !!x.metaData.Doctype ? x.metaData.Doctype : x.metaData.doctype
          const docid = !!x.metaData.Docid ? x.metaData.Docid : x.metaData.docid
          const maindoc = !!x.metaData.Maindoc ? x.metaData.Maindoc : x.metaData.maindoc
          const subdoc = !!x.metaData.Subdoc ? x.metaData.Subdoc : x.metaData.subdoc
          const batchno = !!x.metaData.Batchno ? x.metaData.Batchno : x.metaData.batchno
          return {
            url: x.url,
            metaData: {
              doctype: docType,
              docid: docid,
              maindoc: maindoc,
              subdoc: subdoc,
              batchno: batchno
            },
            source: 'Azure'
          }
        }),
        ...res.archivedDocuments.map((x) => ({ ...x, source: 'Filenet' }))
      ]),
      ZIO.map((data) => {
        const dataFilter = data.filter((x) => x.metaData.docid === docIdPendingLetter)
        return dataFilter.length > 0
          ? dataFilter.map((x) => ({
            // caseId: this.caseId,
            url: x.url,
            docType: x.metaData.doctype || '-',
            docId: x.metaData.docid || 'File',
            mainDoc: x.metaData.maindoc || '-',
            subDoc: x.metaData.subdoc || '-',
            batchNum: x.metaData.batchno || '-',
            source: x.source
          }))
          : []
      }),
      ZIO.tap((x) => {
        setDataDoc(x)
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const updateGeneralInfo = () => {
    return pipe(
      props.category === TaskType.Claim
        ? SupplementaryInfoService.getListGeneralInfoCL(
          props.processId,
          getStatusCaseCL === 'Completed' ? 'Complete' : 'Active'
        )
        : SupplementaryInfoService.getListGeneralInfo(props.caseId),
      ZIO.map((res) => {
        const resData = res.data.filter((item) => item.isSuspend === false)
        if (props.category === TaskType.PolicyService) {
          setDataGeneral({
            data: resData
          })
        } else {
          setDataGeneral(res)
        }
      })
    )
  }

  const groups =
    props.processId.length > 0
      ? pipe(
        ZIO.zipPar(
          allowEdit
            ? SupplementaryInfoService.getAllPendingCode(props.processId)
            : ZIO.succeed({ data: [] } as SupplementaryInfo.Groups),
          updateSuppendHistory(),
          showGeneralInfo ? updateGeneralInfo() : ZIO.unit
        ),
        ZIO.map(([groups]) => {
          setGroupCodeList(groups.data ?? [])
          return (groups.data ?? [])
            .filter(
              (x) =>
                ![TaskType.Complaint, TaskType.CustomerService, TaskType.PremiumCollection].includes(
                  props.category as TaskType
                ) ||
                (props.category === TaskType.Complaint && x.code !== 'INT1') ||
                (props.category === TaskType.CustomerService && x.code !== 'INT2') ||
                (props.category === TaskType.PremiumCollection && x.code !== 'AML')
            )
            .filter(({ code }) => {
              return (
                !suspendGroupExclude.includes(code) &&
                (props.isClaim ? suspendGroupApprovedClaim.includes(code) : true)
              )
            })
            .map(({ code, nameEn, nameVi }) => ({
              value: code,
              label: `${code} - ${translate(nameEn, nameVi)}`
            }))
        }),
        bindLoader,
        ErrorHandling.runDidMount()
      )
      : []

  const getNewDataPendingLetter = () => {
    pipe(
      SupplementaryInfoService.getDataPendingLetter(props.caseId),
      ZIO.map((res) => {
        let valueDescription = ''
        res.letterContents?.map((item) => {
          valueDescription = valueDescription += (valueDescription ? '\n' : '') + item
        })
        setDatadGroupCode({
          suspendGroup: res.suspendGroup ?? '',
          suspendGroupVN: res.suspendGroupVN ?? '',
          unsuspendDate: res.unsuspendDate ?? ''
        })
        setPendingLetters(valueDescription)
        form.setValue('description', valueDescription)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => showGlobalLoading(loading), [loading])

  React.useEffect(() => {
    if (casePS) {
      getNewDataPendingLetter()
      updateGeneralInfoDoc()
    }
  }, [])

  React.useEffect(() => {
    setMaxDateForVRCase()
    return () => {
      setIDC_MAXDATE(undefined)
    }

  }, [isFocused])

  const setMaxDateForVRCase = () => {
    if (isFocused && props.category === TaskType.IDC && allowEdit) {
      pipe(
        SupplementaryInfoService.getMaxDateForVoiceRecordingSupplementary(props.caseId),
        ZIO.map((responseData) => {
          const IDCMaxDate = props.category === TaskType.IDC ?
            pipe(
              props.firstSuspendDate,
              O.fromNullable,
              O.fold(
                () => {
                  return pipe(responseData.find((x) => x.suspendCode === "VOI01"),
                    O.fromNullable,
                    O.fold(
                      () => moment(new Date()).add(12, 'days').toDate(),
                      (item) => moment(item.maxPendingTime).toDate()
                    )
                  )
                },
                (firstPendingDate) => {
                  const customedMaxDay = pipe(
                    responseData.find((x) => x.suspendCode === "VOI02"),
                    O.fromNullable,
                    O.fold(
                      () => moment(firstPendingDate).add(20, 'days').toDate(),
                      (item) => {
                        return moment(item.maxPendingTime).toDate()
                      }
                    )
                  )
                  return customedMaxDay
                }
              )
            ) : undefined
          setIDC_MAXDATE(IDCMaxDate)
          return responseData
        }),
        ZIO.mapError((error) => {
          const IDCMaxDate = props.category === TaskType.IDC ?
            pipe(
              props.firstSuspendDate,
              O.fromNullable,
              O.fold(
                () => moment(new Date()).add(12, 'days').toDate(),
                (itemData) => {
                  const customedMaxDay = moment(itemData).add(20, 'days').toDate()
                  return customedMaxDay
                }
              )
            ) : undefined
          //set default value for api system error
          setIDC_MAXDATE(IDCMaxDate)
          return error
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const cancel = () => {
    form.reset({ status: null, pendingLetter: [], types: undefined })
    setAllowPendingLetter(false)
    // info && initSuspendHistory(info.historyList.data)
  }

  const updateHistoryList = (list: SupplementaryInfoForm.History[]) => {
    const data = updatedData(list)
    return data.length > 0 && props
      ? SupplementaryInfoService.updateList(
        {
          processInstanceId: props.processId,
          data: data.map((x) => ({ ...x, status: x.status.value }))
        },
        []
      )
      : ZIO.succeed(null)
  }

  const getSuspendGroupLabel = (suppendCode: string) => {
    const filterSuspendGroup = groups?.find((item) => item.value === suppendCode)
    return filterSuspendGroup?.label || '-'
  }

  const updateHistoryListTimeout = (list: SupplementaryInfoForm.History[]) => {
    const data = updateTimeout(list)
    return data.length > 0 && props
      ? SupplementaryInfoService.updateList(
        {
          processInstanceId: props.processId,
          data: data.map((x) => ({ ...x, status: x.status.value ?? '' }))
        },
        []
      )
      : ZIO.succeed(null)
  }

  // const validSave = () => {
  //   const formStatus = form.getValues().status
  //   const notValid =
  //     getValues().data.filter(({ status }) => status.value === SupplementaryInfoForm.Status.ACTIVE).length > 0 &&
  //     !!formStatus &&
  //     formStatus.value === SupplementaryInfoForm.Status.ACTIVE
  //   notValid && showToast(t('message:MS050270'), 'error')
  //   return !notValid
  // }

  const validUpdate = () => {
    const notValid = _.map(
      _.groupBy(getValues().data, 'suspendCode'),
      (suspendCode) =>
        _.filter(suspendCode, ({ status }) => status.value === SupplementaryInfoForm.Status.ACTIVE).length
    ).some((count) => count === 2)
    notValid && showToast(t('message:MS050270'), 'error')
    return !notValid
  }

  const validPreview = () => {
    const status = form.getValues('status.value')
    const group = form.getValues('group')
    const types = form.getValues('types')
    if (status && group && types) return true
    showToast(t('message:MS020039'), 'error')
    return false
  }

  // const getFilesUpload = (metaData?: StorageBlob.MetaDataUpload) => {
  //   const { pendingLetter } = form.getValues()
  //   const files: StorageBlob.FileContent[] = pendingLetter.map((x) => ({
  //     file: x.file,
  //     metaData
  //   }))
  //   return files
  // }

  const validTimeoutUpdate = () => {
    let valid = true
    const currentDateTime = new Date()
    formTableArray.fields.filter((item, i) => {
      if (item?.expiredDate instanceof Date) {
        if (isNaN(item?.expiredDate.getTime()) == true) valid = false
        const expiredDateDate = item.expiredDate
        const diffTime = expiredDateDate.getTime() - currentDateTime.getTime()
        if (expiredDateDate.getFullYear() % 100 < currentDateTime.getFullYear() % 100) valid = false
        if (PulseOpsFormat.getDifferenceInDays(expiredDateDate, currentDateTime) < 0) {
          formTableArray.update(i, {
            ...item,
            errorExpiredDate: t('message:MS020043')
          })
          valid = false
        } else if (PulseOpsFormat.getDifferenceInDays(expiredDateDate, currentDateTime) === 0 && diffTime < 0) {
          formTableArray.update(i, {
            ...item,
            errorExpiredDate: t('message:MS020043')
          })
          valid = false
        } else if (props.category === TaskType.IDC && moment(expiredDateDate).valueOf() > moment(IDC_MAXDATE as Date).valueOf()) {
          formTableArray.update(i, {
            ...item,
            errorExpiredDate: t('message:MS020058')
          })
          valid = false
        }
      }
    })
    return valid
  }

  const getMetaData = () => {
    const { pendingLetter } = form.getValues()
    let transactionType = 'SUPPLEMENTARY_INFO',
      docTypeCode = ''

    switch (form.getValues().group?.value) {
      case SupplementaryInfoForm.GroupCode.DOC1:
        docTypeCode = 'DPS16'
        break
      case SupplementaryInfoForm.GroupCode.DOC2:
        docTypeCode = 'DPS16'
        break
      case SupplementaryInfoForm.GroupCode.PRE1:
        docTypeCode = 'DPS16'
        break
      // case SupplementaryInfoForm.GroupCode.DOC3:
      //   docTypeCode = 'DCH02'
      //   break
      case SupplementaryInfoForm.GroupCode.DOC4:
        docTypeCode = 'DCL14'
        break
      // case SupplementaryInfoForm.GroupCode.PAY3:
      //   docTypeCode = 'DCL14'
      //   break
      case SupplementaryInfoForm.GroupCode.DOC5:
        docTypeCode = 'DCL14'
        break
    }
    // return !!docTypeCode && pendingLetter.length > 0
    //   ? pipe(
    //       GeneralService.getMetaData(transactionType, docTypeCode),
    //       ZIO.map(({ data }) => {
    //         const metaData: StorageBlob.MetaDataUpload = {
    //           polnum: props.policyNum,
    //           type: data.transactionType,
    //           doctype: data.doctypeEn,
    //           class: data.classFilenet,
    //           docid: data.docID,
    //           maindoc: data.mainDoc,
    //           subdoc: data.subDoc,
    //           batchno: data.batchNo
    //         }
    //         return getFilesUpload(metaData)
    //       })
    //     )
    //   : ZIO.succeed(getFilesUpload())
    return {
      transactionType,
      docTypeCode,
      pendingLetter
    }
  }

  const getTimneout = (type: string) => {
    const findLabel = letterAndTypes.find((item) => type === item.value)
    if (findLabel && findLabel.timeout && props.category === TaskType.IDC) {
      const customedTimeout = pipe(
        props.firstSuspendDate,
        O.fromNullable,
        O.fold(
          () => moment(new Date()).add(12, 'days').toDate(),
          (itemData) => {
            const customedMaxDay = moment(itemData).add(20, 'days').toDate()
            return customedMaxDay
          }
        )
      )
      return customedTimeout
    } else if (findLabel && findLabel.timeout && showColumnTimeout) {
      return moment(findLabel.timeout).utc(true).toDate() ?? undefined
    } else {
      return undefined
    }
  }

  const onSubmit = async () => {
    const letterCL = checkDocCL(form.watch('group')?.value ?? '') ? await getDataCreateFile() : undefined
    const selectedGroup = (form.getValues().group?.value || '') as SupplementaryInfoForm.GroupCode
    const { types } = form.getValues()
    const filter = types
      ? types.map((item) =>
        formTableArray.fields.some(
          (_) =>
            _.suspendGroupCode === selectedGroup &&
            _.suspendCode === item?.value &&
            _.status.value === SupplementaryInfoForm.Status.ACTIVE
        )
      )
      : []

    if (filter.length > 0) {
      if (filter.includes(true)) {
        showToast(t('message:MS990064'), 'error')
      } else if (!errorForm.getValues('isPreviewed') && casePS) {
        showToast(t('message:MS020050'), 'error')
      } else {
        const letter = checkDocPS(form.watch('group')?.value ?? '') ? await getDataCreateFile() : undefined
        const saveGroup =
          ([
            SupplementaryInfoForm.GroupCode.DOC1,
            SupplementaryInfoForm.GroupCode.DOC2,
            SupplementaryInfoForm.GroupCode.PRE1
          ].includes(selectedGroup) &&
            'PS') ||
          ([SupplementaryInfoForm.GroupCode.DOC4, SupplementaryInfoForm.GroupCode.PAY3].includes(selectedGroup) &&
            'CL') ||
          'PS'

        form.trigger().then(
          (formStatus) => {
            if (!formStatus) return
            // if (validSave()) {
            const { status, types } = form.getValues()
            pipe(
              // getMetaData(),
              // ZIO.flatMap((files) => StorageBlob.uploadToSubmit(saveGroup, props.policyNum)(files)),
              ZIO.effect(() => {
                return getMetaData()
              }),
              ZIO.flatMap((metaDataInfo) =>
                GeneralService.getAzureStorageFiles(
                  metaDataInfo.pendingLetter,
                  metaDataInfo.transactionType,
                  metaDataInfo.docTypeCode,
                  saveGroup,
                  props.policyNum,
                  props.officeCode ?? ''
                )
              ),
              ZIO.tapError((_) => {
                showToast(t('message:MS050001'), 'error')
                return ZIO.unit
              }),
              ZIO.tap((images) => {
                // const data: SupplementaryInfo.Save = {
                //   processInstanceId: props.processId,
                //   data: types.map((type) => ({
                //     pendingLetters: images.map(({ name }) => name),
                //     status: status?.value || '-',
                //     suspendCode: type.value,
                //     user: info?.currentUser || '-'
                //   }))
                // }
                return pipe(
                  AuthService.userInfo,
                  ZIO.flatMap((user) =>
                    SupplementaryInfoService.save(
                      {
                        processInstanceId: props.processId,
                        data: types.map((type) => ({
                          pendingLetters: images.map(({ name }) => name),
                          status: status?.value || '-',
                          suspendCode: type.value,
                          user: user.email,
                          expiredDate: getTimneout(type.value)
                        }))
                      },
                      images,
                      props?.category === TaskType.Claim ? letterCL : letter,
                      props?.category === TaskType.Claim ? letterCL : undefined,
                      props.category === TaskType.Claim ? TaskType.Claim : ''
                    )
                  ),
                  ZIO.flatMap(() => reloadSuppendHistory(false)),
                  ZIO.flatMap(() => (showGeneralInfo ? updateGeneralInfo() : ZIO.unit))
                )
              }),
              bindLoader,
              ZIO.unsafeRun({})
            )
            if (casePS) updateGeneralInfoDoc()
          }
          // }
        )
      }
    }
  }

  const reloadSuppendHistory = (isSave: boolean = true) => {
    return pipe(
      updateSuppendHistory(),
      ZIO.tap(() => {
        showToast(t('common:SaveSuccess'), 'success')
        form.setValue('types', [])
        if (casePS) {
          getNewDataPendingLetter()
        } else {
          form.setValue('description', '')
        }
        form.setValue('status', null)
        form.setValue('pendingLetter', [])
        form.setValue('group', null)
        setTypes([])
        isSave && cancel()
        return ZIO.unit
      })
    )
  }

  const update = () => {
    if (validUpdate()) {
      pipe(
        updateHistoryList(formTableArray.fields),
        ZIO.flatMap(() => reloadSuppendHistory(false)),
        ZIO.flatMap(() => (showGeneralInfo ? updateGeneralInfo() : ZIO.unit)),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }

  const compareDates = (d1: string, d2: string) => {
    let date1 = new Date(d1).getTime()
    let date2 = new Date(d2).getTime()
    if (d1 && date1 <= date2) {
      return d1
    } else {
      return d2
    }
  }

  const compareDatesForPreview = (d1: string, d2: string) => {
    if (!!d1 && !!d2) return PulseOpsFormat.datetoFormat(new Date(compareDates(d1, d2)), 'DD/MM/YYYY')
    else if (!!d1) return PulseOpsFormat.datetoFormat(new Date(d1), 'DD/MM/YYYY')
    else if (!!d2) return PulseOpsFormat.datetoFormat(new Date(d2), 'DD/MM/YYYY')
    else return dataGroupCode?.unsuspendDate ?? ''
  }

  const getAllSuspendGroupName = () => {
    if (info && info.historyList.data.length > 0) {
      const listSuspendGroup = [] as string[]
      info?.historyList.data.map((item) => {
        if (item.status === 'Active' && item.suspendGroupCode && !listSuspendGroup.includes(item.suspendGroupCode)) {
          listSuspendGroup.push(item.suspendGroupCode)
        }
      })
      const groupSelected = form.getValues('group')?.value
      let suspendGroupName =
        groupSelected && !listSuspendGroup.includes(groupSelected)
          ? groupCodeList.find((item) => item.code === form.getValues('group')?.value)?.nameVi ?? ''
          : ''
      listSuspendGroup.forEach((item) => {
        const name = groupCodeList.find((group) => group.code === item)
        if (name) {
          suspendGroupName = suspendGroupName ? suspendGroupName + ', ' + name.nameVi : name.nameVi
        }
      })
      return suspendGroupName
    } else {
      return groupCodeList.find((item) => item.code === form.getValues('group')?.value)?.nameVi ?? ''
    }
  }

  const getDataCreateFile = () => {
    const selectedGroup = (form.getValues('group')?.value ||
      (dataGroupCode?.suspendGroup ?? '')) as SupplementaryInfoForm.GroupCode
    const selectedGroupName = casePS ? getAllSuspendGroupName() : ''
    const letterContent = form.getValues('description')?.split('\n')
    let minTime = ''
    let selectedCodes: string[] = []
    let selectedTypes: string[] = []
    let selectedTypesVN: string[] = []
    const types = form.getValues('types')
    if (types) {
      types.map((item) => {
        const findLabel = letterAndTypes.find((type) => item.value === type.value)
        if (findLabel && findLabel.timeout) {
          if (props.category === TaskType.Claim) {
            minTime = minTime && findLabel.timeout ? compareDates(minTime, findLabel.timeout) : findLabel.timeout
            selectedTypes.push(findLabel.label)
            selectedTypesVN.push(findLabel.lableVN)
            selectedCodes.push(findLabel.value)
          } else if (props.category === TaskType.PolicyService) {
            minTime = minTime && findLabel.timeout ? compareDates(minTime, findLabel.timeout) : findLabel.timeout
            selectedTypes.push(findLabel.label)
            selectedCodes.push(findLabel.value)
          }
        }
      })
    }
    const timeoutInList = findMinTimeout()
    return pipe(
      ZIO.zipPar(
        SubmissionService.getDataPendingLetterPS(props.policyNum),
        AuthService.userInfo,
        PulseOpsApi.getHistoricDetail(props.processId)
      ),
      ZIO.map(([owner, userInfo, historyItem]) => {
        const today = new Date()
        return {
          transactionType:
            props.category === TaskType.PolicyService
              ? t2(TransactionLabel(props.transaction as TransactionType)) ?? ''
              : props.transaction,
          businessKey: props.caseId ?? '',
          suspendCodes: selectedCodes,
          suspendGroup: suspendGroupApproved.includes(selectedGroup) ? GroupCode.DOC1 : selectedGroup,
          suspendTypes: selectedTypes,
          policyNumber: props.policyNum,
          policyOwnerName: owner?.name ?? '',
          unsuspendDate:
            props.category === TaskType.PolicyService
              ? compareDatesForPreview(minTime, timeoutInList)
              : PulseOpsFormat.datetoFormat(new Date(minTime), 'DD/MM/YYYY'),
          letterContents: letterContent || [],
          userID: userInfo.email,
          clientId: props.category === TaskType.PolicyService ? owner?.clientId ?? '' : '',
          payeeName:
            TaskType.Claim === props.category
              ? detailPayload?.detail?.payload?.body?.attributes?.CLAIM_ANT_NAME ?? ''
              : undefined,
          addresses:
            TaskType.Claim === props.category
              ? [detailPayload?.detail?.payload?.body?.attributes?.CLAIM_ANT_ADDRESS ?? '']
              : undefined,
          phoneNumber:
            TaskType.Claim === props.category
              ? detailPayload?.detail?.payload?.body?.attributes?.CLAIM_ANT_PHONE ?? ''
              : owner.phoneNumber ?? '',
          dayTime: TaskType.Claim === props.category ? today.getDate() : undefined,
          monthTime: TaskType.Claim === props.category ? today.getMonth() + 1 : undefined,
          yearTime: TaskType.Claim === props.category ? today.getFullYear() : undefined,
          claimNo: TaskType.Claim === props.category ? detailPayload?.claimNumber ?? '' : undefined,
          policyNo: TaskType.Claim === props.category ? detailPayload?.policyRider?.policyNo ?? '' : undefined,
          assuranceType:
            TaskType.Claim === props.category
              ? listClaim?.find((item) => item?.claimCode === claimType?.claimTypeCode)?.claimTypeVN || ''
              : undefined,
          isUploadedToAzure: false,
          poName: TaskType.Claim === props.category ? detailPayload?.policy?.owners?.name ?? '' : undefined,
          laName:
            TaskType.Claim === props.category
              ? detailPayload?.detail?.payload.body?.lifeAssured?.name ?? ''
              : undefined,
          time:
            TaskType.Claim === props.category
              ? moment(today.setDate(today.getDate() + 45)).format('DD/MM/yyyy')
              : undefined,
          suspendTypesVN: TaskType.Claim === props.category ? selectedTypesVN : undefined,
          typeGenerateLetter: props.category === TaskType.Claim ? TaskType.Claim : '',
          claimType:
            props.category === TaskType.Claim
              ? listClaim?.find((item) => item?.claimCode === claimType?.claimTypeCode)?.claimTypeVN || ''
              : '',
          claimTypeCode:
            props.category === TaskType.Claim
              ? listClaim?.find((item) => item?.claimCode === claimType?.claimTypeCode)?.claimCode || ''
              : '',
          processInstanceId: props.category === TaskType.Claim ? props.processId : '',
          officeCode: props.officeCode ?? '',
          requestDate: PulseOpsFormat.datetoFormat(new Date(historyItem.payload.createdDate ?? ''), 'DD/MM/YYYY'),
          suspendGroupVN: detail.category === TaskType.PolicyService ? selectedGroupName : '',
          street: detail.category === TaskType.PolicyService ? owner?.street ?? '' : '',
          ward: detail.category === TaskType.PolicyService ? owner?.ward ?? '' : '',
          district: detail.category === TaskType.PolicyService ? owner.district ?? '' : '',
          city: detail.category === TaskType.PolicyService ? owner.city ?? '' : '',
          locationCode: detail.category === TaskType.PolicyService ? owner.locationCode ?? '' : '',
          clientNumber: props.category === TaskType.Claim ? detailPayload.clientIdNumber : undefined,
          claimAntEmail:
            props.category === TaskType.Claim
              ? detailPayload.detail.payload.body.attributes.CLAIM_ANT_EMAIL
              : undefined,
          claimOwnerEmail:
            props.category === TaskType.Claim
              ? detailPayload.detail.payload.body.policy.owners?.contactDetails.EMAIL.value
              : undefined,
          exchangeId: TaskType.Claim === props.category ? detailPayload?.detail.payload.exchangeId ?? '' : undefined,
          claimantName:
            TaskType.Claim === props.category
              ? detailPayload?.detail.payload.body.attributes.CLAIM_ANT_NAME ?? ''
              : undefined,
          claimantPhone:
            props.category === TaskType.Claim
              ? detailPayload?.detail.payload.body.attributes.CLAIM_ANT_PHONE
              : undefined,
          claimOwnerPhone:
            props.category === TaskType.Claim
              ? detailPayload?.detail.payload.body.policy.owners?.contactDetails.PHONE.value
              : undefined
        }
      }),
      bindLoader,
      ErrorHandling.run({})
    )
  }

  const getDataReviewFile = (data: SupplementaryInfo.GeneralInfo) => {
    return {
      transactionType: data.transactionType ?? '',
      businessKey: data.businessKey ?? '',
      suspendCodes: data.suspendCodes ?? [],
      suspendGroup: data.suspendGroup ?? '',
      suspendTypes: data.suspendTypes ?? [],
      policyNumber: data.policyNumber ?? '',
      policyOwnerName: data.policyOwnerName ?? '',
      unsuspendDate: data.unsuspendDate ?? '',
      letterContents: data.letterContents ?? [],
      userID: data.userID ?? '',
      clientId: data.clientId ?? '',
      payeeName: data.payeeName ?? '',
      addresses: data.addresses ?? [],
      phoneNumber: data.phoneNumber ?? '',
      dayTime: data?.dayTime ?? undefined,
      monthTime: data?.monthTime ?? undefined,
      yearTime: data?.yearTime ?? undefined,
      claimNo: data.claimNo ?? '',
      policyNo: data.policyNo ?? '',
      assuranceType: data.assuranceType ?? '',
      isUploadedToAzure: data.isUploadedToAzure ?? false,
      poName: data.poName ?? '',
      laName: data.laName ?? '',
      time: data.time ?? '',
      suspendTypesVN: data.suspendTypesVN ?? '',
      claimType: data.claimType ?? '',
      processInstanceId: data.processInstanceId ?? '',
      officeCode: props.officeCode ?? '',
      suspendGroupVN: data.suspendGroupVN ?? '',
      street: data.street ?? '',
      ward: data.ward ?? '',
      district: data.district ?? '',
      city: data.city ?? '',
      locationCode: data.locationCode ?? '',
      clientNumber: data.clientNumber ?? '',
      claimAntEmail: data.claimAntEmail ?? '',
      claimOwnerEmail: data.claimOwnerEmail ?? ''
    }
  }

  const previewLetter = async (preViewAgain: boolean, row?: SupplementaryInfo.GeneralInfo) => {
    const dataPostPreview = preViewAgain && row ? getDataReviewFile(row) : await getDataCreateFile()
    pipe(
      props.category === TaskType.Claim
        ? SupplementaryInfoService.previewLetterCL(dataPostPreview)
        : SupplementaryInfoService.previewLetter(dataPostPreview),
      ZIO.flatMap((responseData) => {
        return ZIO.zipPar(
          ZIO.succeed(responseData),
          ZIO.fromPromise(() => responseData.blob())
        )
      }),
      ZIO.map(([res, blob]) => {
        const fileString = window.URL.createObjectURL(blob)
        window.open(fileString, '_blank')
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.run({})
    )
  }

  const saveTime = () => {
    if (validTimeoutUpdate()) {
      pipe(
        updateHistoryListTimeout(formTableArray.fields),
        ZIO.flatMap(() => reloadSuppendHistory(false)),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }

  // const viewUploadFile = async (pendingFile: SupplementaryInfoForm.PendingLetter) => {
  //   return await pipe(
  //     ZIO.zipPar(
  //       ZIO.succeed(pendingFile.url),
  //       StorageBlobApi.getRemoteConfig(props.category, props.policyNum),
  //       AuthService.token,
  //       AuthService.getLoginType
  //     ),
  //     ZIO.flatMap(([url, storageConfig, token, loginType]) => {
  //       return pipe(
  //         ZIO.fromPromise(() => {
  //           const apiUrl = url.split('?')
  //           const storageUrl = apiUrl[0]
  //           const paramInfo = apiUrl[1]
  //           const paramList = paramInfo.split('&')
  //           const containerName = !!paramList[0] ? paramList[0].split('=')[1] : ''
  //           const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
  //           return fetch(storageUrl, {
  //             method: 'GET',
  //             headers: {
  //               'Cache-Control': 'no-cache',
  //               Pragma: 'no-cache',
  //               Container: containerName,
  //               Token: storageConfig.sas,
  //               Blob: blobName,
  //               Authorization: `Bearer ${token}`,
  //               'X-Authen-Vendor': loginType
  //             }
  //           })
  //         })
  //       )
  //     }),
  //     ZIO.flatMap((res) =>
  //       ZIO.zipPar(
  //         ZIO.succeed(res),
  //         ZIO.fromPromise(() => res.blob())
  //       )
  //     ),
  //     ZIO.map(([response, blob]) => ({
  //       response,
  //       blob
  //     })),
  //     bindLoader,
  //     ErrorHandling.run()
  //   )
  // }

  const viewPendingList = (pendingLetterList: SupplementaryInfoForm.PendingLetter[]) => {
    pendingLetterList &&
      pendingLetterList.length > 0 &&
      pipe(
        ZIO.zipPar(
          StorageBlobApi.getRemoteConfig(props.category, props.policyNum),
          AuthService.token,
          AuthService.getLoginType
        ),
        ZIO.flatMap(([storageConfig, token, loginType]) => {
          return pipe(
            pendingLetterList,
            A.map((pendingItem) => {
              const url = pendingItem.url
              return pipe(
                ZIO.fromPromise(() => {
                  const apiUrl = url.split('?')
                  const storageUrl = apiUrl[0]
                  const paramInfo = apiUrl[1]
                  const paramList = paramInfo.split('&')
                  const containerName = !!paramList[0] ? paramList[0].split('=')[1] : ''
                  const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
                  return fetch(storageUrl, {
                    method: 'GET',
                    headers: {
                      'Cache-Control': 'no-cache',
                      Pragma: 'no-cache',
                      Container: containerName,
                      Token: storageConfig.sas,
                      Blob: blobName,
                      Authorization: `Bearer ${token}`,
                      'X-Authen-Vendor': loginType
                    }
                  })
                }),
                ZIO.flatMap((res) =>
                  ZIO.zipPar(
                    ZIO.succeed(res),
                    ZIO.fromPromise(() => res.blob())
                  )
                ),
                ZIO.map(([response, blob]) => ({
                  response,
                  blob,
                  pendingItem
                }))
              )
            }),
            ZIO.sequence,
            ZIO.map((responseData) => {
              responseData.forEach((item) => {
                const isImageFile = imgExtensions.includes(
                  item.pendingItem.azureBlobName.slice(item.pendingItem.azureBlobName.lastIndexOf('.'))
                )
                if (isImageFile) {
                  const imageFile = window.URL.createObjectURL(item.blob)
                  setImage([imageFile])
                  setOpen(true)
                } else {
                  const type = item.response.headers.get('content-type') || ''
                  downloadURI(window.URL.createObjectURL(item.blob), `file.${type.split('/')[1]}`)
                }
              })
            })
          )
        }),
        bindLoader,
        ErrorHandling.run()
      )
  }

  const viewAddLackDocs = (addLackDocs: SupplementaryInfoForm.PendingLetter[]) => {
    addLackDocs &&
      addLackDocs.length > 0 &&
      pipe(
        ZIO.zipPar(
          StorageBlobApi.getRemoteConfig(props.category, props.policyNum),
          AuthService.token,
          AuthService.getLoginType
        ),
        ZIO.flatMap(([storageConfig, token, loginType]) => {
          setImage([])
          return pipe(
            addLackDocs,
            A.map((pendingItem) => {
              const nameUrl = pendingItem.azureBlobName?.split('/').splice(0, 1).join('/')
              const nameUrlImage = pendingItem.azureBlobName?.split('/').splice(-1, 1).join('/')
              const urlImage =
                pendingItem?.url?.split('/').splice(0, 5).join('/') +
                '?container=pulseforops' +
                '&blob=' +
                nameUrl +
                '/' +
                nameUrlImage
              return pipe(
                ZIO.fromPromise(() => {
                  const apiUrl = urlImage.split('?')
                  const storageUrl = apiUrl[0]
                  const paramInfo = apiUrl[1]
                  const paramList = paramInfo.split('&')
                  const containerName = !!paramList[0] ? paramList[0].split('=')[1] : ''
                  const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
                  return fetch(storageUrl, {
                    method: 'GET',
                    headers: {
                      'Cache-Control': 'no-cache',
                      Pragma: 'no-cache',
                      Container: containerName,
                      Token: storageConfig.sas,
                      Blob: blobName,
                      Authorization: `Bearer ${token}`,
                      'X-Authen-Vendor': loginType
                    }
                  })
                }),
                ZIO.flatMap((res) =>
                  ZIO.zipPar(
                    ZIO.succeed(res),
                    ZIO.fromPromise(() => res.blob())
                  )
                ),
                ZIO.map(([response, blob]) => ({
                  response,
                  blob,
                  pendingItem
                }))
              )
            }),
            ZIO.sequence,
            ZIO.map((responseData) => {
              let blobImageList: React.SetStateAction<string[]> = []
              responseData.forEach((item) => {
                const isImageFile = imgExtensions.includes(
                  item.pendingItem.azureBlobName.slice(item.pendingItem.azureBlobName.lastIndexOf('.'))
                )
                if (isImageFile) {
                  const imageFile = window.URL.createObjectURL(item.blob)
                  blobImageList.push(imageFile)
                  setOpen(true)
                } else {
                  const type = item.response.headers.get('content-type') || ''
                  downloadURI(window.URL.createObjectURL(item.blob), `file.${type.split('/')[1]}`)
                }
              })
              setImage(blobImageList)
            })
          )
        }),
        bindLoader,
        ErrorHandling.run()
      )
  }

  const canUpdate = formTableArray && updatedData([...formTableArray.fields]).length > 0
  const canUpdateTimeout = formTableArray && updateTimeout([...formTableArray.fields]).length > 0

  const onPressSuspendWriteOff = () => {
    return pipe(
      ZIO.succeed([{ processInstanceId: props.processId }]),
      ZIO.flatMap((item) => {
        return Alert.modalM({
          title: '',
          content: t('message:MS990088'),
          size: {
            width: '50%'
          },
          onOK: () => {
            pipe(
              CashLessService.SuspendWriteOffAction(item),
              ZIO.map((res) => {
                showToast(t('requestInfo:SuspendWOSuccess'), 'success')
                onHandleActionSuccess && onHandleActionSuccess()
                return ZIO.unit
              }),
              ZIO.catchAll((error: any) => {
                showToast(error?.source?.response?.data.responseStatus.message, 'error')
                return ZIO.succeed(null)
              }),
              ZIO.unsafeRun({})
            )
          },
          onClose: () => {
            console.log('cancel')
          }
        })
      }),
      ErrorHandling.run()
    )
  }

  React.useEffect(() => {
    if (!['PS', 'CH', 'CS', 'IDC'].includes(props.category)) {
      getFooter(
        allowEdit ? (
          <View
            style={[sharedStyle.footer, isWide ? { marginRight: 19 } : { width: '100%', justifyContent: 'center' }]}
          >
            <TouchableOpacity
              style={[sharedStyle.button, { borderColor: '#ED1B2E' }]}
              onPress={() => {
                cancel()
              }}
            >
              <Text style={sharedStyle.textButton}>{t('common:Cancel')}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[sharedStyle.button, canUpdate ? sharedStyle.btnDisabled : sharedStyle.btnRed]}
              disabled={canUpdate}
              onPress={() => {
                onSubmit()
              }}
            >
              <Text style={!canUpdate ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled}>
                {t('SupplementaryInfo:AddSuspend')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[sharedStyle.button, !canUpdate ? sharedStyle.btnDisabled : sharedStyle.btnRed]}
              disabled={!canUpdate}
              onPress={() => {
                update()
              }}
            >
              <Text style={canUpdate ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled}>
                {t('common:Update')}
              </Text>
            </TouchableOpacity>
          </View>
        ) : suspendWriteOff ? (
          <View
            style={[sharedStyle.footer, isWide ? { marginRight: 19 } : { width: '100%', justifyContent: 'center' }]}
          >
            <TouchableOpacity style={[sharedStyle.button, { borderColor: '#ED1B2E' }]} onPress={onPressSuspendWriteOff}>
              <Text style={sharedStyle.textButton}>{t('TaskManagement:SuspendWriteOff')}</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <></>
        )
      )
    }
  }, [canUpdate, allowEdit, loading, formTableArray.fields, props.category])

  React.useEffect(() => {
    setSelected(activeTabs[0] || null)
  }, [formTableArray.fields])

  React.useEffect(() => {
    return () => {
      getFooter(<></>)
    }
  }, [])

  const findMinTimeout = () => {
    let minTime = ''
    if (info && info.historyList) {
      info.historyList?.data.map((item) => {
        if (item.expiredDate && item.status === 'Active')
          minTime = minTime ? compareDates(minTime, item.expiredDate) : item.expiredDate
      })
    }
    return minTime
  }

  const onChangeSuspendGroup = (value: string) => {
    pipe(
      SupplementaryInfoService.getPendingCodeBySuspendCode(props.processId, value),
      ZIO.tap((res) => {
        form.setValue('types', [])
        setLetterAndTypes(
          res.data.map(({ code, typeEn, typeVn, timeout, description }) => ({
            value: code,
            label: typeEn,
            timeout: timeout ?? '',
            description: description ? description : [],
            lableVN: typeVn
          }))
        )
        setTypes(
          res.data
            .filter((item) => item.code !== 'SPC10' && item.code !== 'SPS11')
            .map(({ code, typeEn, typeVn }) => ({
              value: code,
              label: translate(typeEn, typeVn)
            }))
            .sort(function (a, b) {
              if (a.label < b.label) {
                return -1
              }
              if (a.label > b.label) {
                return 1
              }
              return 0
            })
        )
        const approvedList = props.isClaim ? suspendGroupApprovedClaim : suspendGroupApproved
        setAllowPendingLetter(approvedList.includes(value))
        !allowPendingLetter && form.setValue('pendingLetter', [])
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const checkDocPS = (doc: string) => {
    return doc && (doc === GroupCode.DOC1 || doc === GroupCode.DOC2 || doc === GroupCode.PRE1)
  }

  const checkDocCL = (doc: string) => {
    return TaskType.Claim === props.category && doc && (doc === GroupCode.DOC4 || doc === GroupCode.DOC5)
  }

  const checkShowButtonSuppend = () => {
    const suppendTypes = form.watch('types')
    const status = form.watch('status.value') === SupplementaryInfoForm.Status.ACTIVE
    return suppendTypes && suppendTypes.length > 0 && status
  }

  const getLink = (url: string, source: string) => {
    source !== 'Azure'
      ? pipe(
        ZIO.zipPar(AuthService.token, AuthService.getLoginType),
        ZIO.flatMap(([token, loginType]) => {
          return ZIO.fromPromise(() =>
            fetch(url, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            })
          )
        }),
        ZIO.flatMap((res) =>
          ZIO.zipPar(
            ZIO.succeed(res),
            ZIO.fromPromise(() => res.blob())
          )
        ),
        ZIO.map(([res, blob]) => {
          const type = res.headers.get('content-type') || ''
          downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
        }),
        ErrorHandling.run()
      )
      : pipe(
        ZIO.zipPar(
          StorageBlobApi.getRemoteConfig(props?.category ?? '', props.policyNum),
          AuthService.token,
          AuthService.getLoginType
        ),
        ZIO.flatMap(([storageConfig, token, loginType]) => {
          return pipe(
            ZIO.fromPromise(() => {
              const apiUrl = url.split('?')
              const storageUrl = apiUrl[0]
              const paramInfo = apiUrl[1]
              const paramList = paramInfo.split('&')
              const containerName = !!paramList[0] ? paramList[0].split('=')[1] : ''
              const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
              return fetch(storageUrl, {
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                  Pragma: 'no-cache',
                  Container: containerName,
                  Token: storageConfig.sas,
                  Blob: blobName,
                  Authorization: `Bearer ${token}`,
                  'X-Authen-Vendor': loginType
                }
              })
            })
          )
        }),
        ZIO.flatMap((res) =>
          ZIO.zipPar(
            ZIO.succeed(res),
            ZIO.fromPromise(() => res.blob())
          )
        ),
        ZIO.tap(([res, blob]) => {
          const type = res.headers.get('content-type') || ''
          downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
          return ZIO.unit
        }),
        bindLoader,
        ErrorHandling.run()
      )
  }

  const suspendTab = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>{t('SupplementaryInfo:SuspendGroup')}</TableCell>
              <TableCell>{t('SupplementaryInfo:SuspendType')}</TableCell>
              <TableCell>{t('common:Status')}</TableCell>
              <TableCell>{t('SupplementaryInfo:CreatedUser')}</TableCell>
              <TableCell>{t('common:CreatedDate')}</TableCell>
              <TableCell>{t('SupplementaryInfo:UpdatedUser')}</TableCell>
              <TableCell>{t('SupplementaryInfo:UpdatedDate')}</TableCell>
              {showColumnTimeout && <TableCell>{t('SupplementaryInfo:Timeout')}</TableCell>}
              {props.category === TaskType.Claim ? (
                <>
                  <TableCell>{t('SupplementaryInfo:ReceivedDate')}</TableCell>
                  <TableCell>{t('SupplementaryInfo:Document')}</TableCell>
                </>
              ) : (
                ''
              )}
              {props.category !== TaskType.IDC && (
                <TableCell>
                  {props.category === TaskType.Claim ? t('SupplementaryInfo:AttachedFile') : t('SupplementaryInfo:View')}
                </TableCell>
              )}

            </TableRow>
          </TableHead>
          <TableBody>
            {formTableArray.fields.map((row, i) => {
              const beforeUpdate = row.updatedUser === '-'
              const statusValue = info?.historyList?.data[i]?.status || ''
              let options: SupplementaryInfoForm.SelectOptions = []
              switch (statusValue) {
                case SupplementaryInfoForm.Status.COMPLETE:
                  options = []
                  beforeUpdate ? (options = [...suppendHistoryStatus]) : options.push(suppendHistoryStatus[2])
                  break
                default:
                  options = [...suppendHistoryStatus]
                  break
              }

              return (
                <TableRow
                  key={row.id}
                  style={
                    formTableArray.fields[i].status.value === SupplementaryInfoForm.Status.ACTIVE &&
                      formTableArray.fields[i].addLackDocs?.length > 0
                      ? { backgroundColor: '#ED1B2E0D' }
                      : {}
                  }
                >
                  <TableCell>{row.suspendGroup}</TableCell>
                  <TableCell>{row.suspendType}</TableCell>
                  <TableCell>
                    {allowEdit ? (
                      <SelectTable
                        value={{
                          value: row.status.value,
                          label: row.status.label
                        }}
                        id={`statusrow-${i}`}
                        onChange={(data) => {
                          data &&
                            formTableArray.update(i, {
                              ...row,
                              status: data
                            })
                        }}
                        disabled={!beforeUpdate && row.status.value !== SupplementaryInfoForm.Status.ACTIVE}
                        options={options || []}
                      />
                    ) : (
                      <Text>{row.status.label}</Text>
                    )}
                  </TableCell>
                  <TableCell>{row.createdUser}</TableCell>
                  <TableCell>{row.createdDate}</TableCell>
                  <TableCell>{row.updatedUser}</TableCell>
                  <TableCell>{row.updatedDate}</TableCell>
                  {showColumnTimeout && (
                    <TableCell style={{ width: 190 }}>
                      {allowEdit ? (
                        <CustomedDateTimePicker
                          key={`expiredDate-${i}`}
                          alwaysShow={false}
                          onChange={(data) => {
                            data &&
                              moment(data).isValid() &&
                              formTableArray.update(i, {
                                ...row,
                                errorExpiredDate: '',
                                expiredDate: data
                              })
                          }}
                          value={row.expiredDate ? new Date(row.expiredDate) : null}
                          minDate={currentDate}
                          maxDate={IDC_MAXDATE}
                          hideUnderline={true}
                          maxDateMessage={t('message:MS020058')}
                          disabled={
                            info?.historyList &&
                            info.historyList.data[i]?.status !== SupplementaryInfoForm.Status.ACTIVE
                          }
                          errorMessage={formTableArray.fields[i].errorExpiredDate}
                          minWidth={180}
                        />
                      ) : (
                        <Text>{PulseOpsFormat.datetoFormat(row.expiredDate, 'DD/MM/YYYY HH:mm')}</Text>
                      )}
                    </TableCell>
                  )}
                  {props.category === TaskType.Claim ? (
                    <>
                      <TableCell>{row.receivedDate}</TableCell>
                      <TableCell>
                        {formTableArray.fields[i].addLackDocs?.length > 0 ? (
                          <TouchableOpacity
                            onPress={async () => {
                              const { addLackDocs } = row
                              viewAddLackDocs(addLackDocs)
                            }}
                          >
                            {' '}
                            <assets.DocuemntActive />{' '}
                          </TouchableOpacity>
                        ) : (
                          <assets.DocuemntInactive />
                        )}
                      </TableCell>
                    </>
                  ) : (
                    ''
                  )}
                  {props.category !== TaskType.IDC && (
                    <TableCell>
                      {props.category === TaskType.PremiumCollection ? (
                        row.status.value === 'Complete' && !beforeUpdate ? (
                          <TouchableOpacity
                            onPress={async () => {
                              setSupplementaryId({ id: row.idHistory, modifiedDate: row.updatedDate })
                              setOpenPopupViewSuspend(true)
                            }}
                          >
                            <FontAwesome5 name="eye" color="#ED1B2E" />
                          </TouchableOpacity>
                        ) : (
                          <></>
                        )
                      ) : (
                        <TouchableOpacity
                          onPress={async () => {
                            const { pendingLetters } = row
                            viewPendingList(pendingLetters)
                          }}
                        >
                          <FontAwesome5 name="eye" color="#ED1B2E" />
                        </TouchableOpacity>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
            {formTableArray.fields.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  {t('common:noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const generalTab = () => {
    if (casePS) {
      return (
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>{t('RelatedDoc:docType')}</TableCell>
                <TableCell>{t('RelatedDoc:docId')}</TableCell>
                <TableCell>{t('RelatedDoc:mainDoc')}</TableCell>
                <TableCell>{t('RelatedDoc:subDoc')}</TableCell>
                <TableCell>{t('RelatedDoc:batchNum')}</TableCell>
                <TableCell>{t('RelatedDoc:source')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataDoc &&
                dataDoc.map((row, i) => {
                  return (
                    <TableRow key={`dataDoc_${i}`}>
                      <TableCell>{row.docType}</TableCell>
                      <TableCell>
                        <Text
                          style={{ color: row.source === 'Azure' ? '#1EA5FC' : '#ED1B2E' }}
                          onPress={() => {
                            getLink(row.url, row.source)
                          }}
                        >
                          {row.docId}
                        </Text>
                      </TableCell>
                      <TableCell>{row.mainDoc}</TableCell>
                      <TableCell>{row.subDoc}</TableCell>
                      <TableCell>{row.batchNum}</TableCell>
                      <TableCell>{row.source}</TableCell>
                    </TableRow>
                  )
                })}
              {dataDoc && dataDoc.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                    {t('common:noData')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>{t('SupplementaryInfo:SuspendGroup')}</TableCell>
              <TableCell>{t('SupplementaryInfo:SuspendType')}</TableCell>
              <TableCell>{t('common:CreatedDate')}</TableCell>
              <TableCell>{t('SupplementaryInfo:Letter')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataGeneral &&
              dataGeneral.data
                .filter((r) => !_.isEmpty(r.suspendCodes))
                .map((row, i) => {
                  let suspendTypesData = ''
                  if (props.category === TaskType.Claim) {
                    ; (i18n.language === 'en' ? row.suspendTypes : row.suspendTypesVN)?.map((type) => {
                      suspendTypesData = suspendTypesData ? suspendTypesData + ' - ' + type : type
                    })
                  } else {
                    row.suspendTypes?.map((suspendType) => {
                      suspendTypesData = suspendTypesData ? suspendTypesData + ' - ' + suspendType : suspendType
                    })
                  }

                  return (
                    <TableRow key={`generalRow_${i}`}>
                      <TableCell>{row.suspendGroup}</TableCell>
                      <TableCell>{suspendTypesData}</TableCell>
                      <TableCell>{PulseOpsFormat.dateStringtoFormat(row.createdDate, 'DD/MM/YYYY')}</TableCell>
                      <TableCell>
                        {checkDocPS(row.suspendGroup || '') || checkDocCL(row.suspendGroup || '') ? (
                          <TouchableOpacity onPress={() => previewLetter(true, row)}>
                            <FontAwesome5 name="envelope-open-text" color="#ED1B2E" style={{ fontSize: 18 }} />
                          </TouchableOpacity>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  )
                })}
            {dataGeneral && dataGeneral.data.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  {t('common:noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const activeTabs: Tab[] = showGeneralInfo
    ? [
      {
        id: TabId.SuspendHistory,
        title: t('SupplementaryInfo:SuspendHistory'),
        element: suspendTab
      },
      {
        id: TabId.GeneralLetter,
        title: props.category === TaskType.Claim ? t('Tab:GeneralInfoCL') : t('SupplementaryInfo:GeneralLetter'),
        element: generalTab
      }
    ]
    : [
      {
        id: TabId.SuspendHistory,
        title: t('SupplementaryInfo:SuspendHistory'),
        element: suspendTab
      }
    ]

  return (
    <View>
      <View style={{ paddingBottom: 100 }}>
        <View style={style.section}>
          <Text style={style.titleSectionText}>{t('SupplementaryInfo:AddSuspend')}</Text>
          <View style={[style.sectionInfo, { flexDirection: 'row', flexWrap: 'wrap' }]}>
            <View style={{ width: '33.3333333%', paddingRight: 10 }}>
              <Controller
                name="group"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('SupplementaryInfo:SuspendGroup') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    label={t('SupplementaryInfo:SuspendGroup')}
                    required
                    options={groups || []}
                    value={value}
                    disabled={disable}
                    errorMessage={error?.message}
                    onChange={(groupCode) => {
                      onChange(groupCode)
                      if (!casePS) form.setValue('description', '')
                      groupCode && onChangeSuspendGroup(groupCode.value)
                    }}
                  />
                )}
              />
            </View>
            <View style={{ width: '33.3333333%', paddingRight: 10 }}>
              <Controller
                name="types"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('SupplementaryInfo:SuspendType') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    label={t('SupplementaryInfo:SuspendType')}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    disabled={disable}
                    value={value}
                    multiple
                    required
                    errorMessage={error?.message}
                    onChange={(value) => {
                      errorForm.setValue('isPreviewed', false)
                      // const currentSuppendTypes = form.watch('types')
                      let valueDescription = ''
                      const checkContentDesc = (desc: string) => {
                        return desc === '-' ? true : false
                      }
                      if (casePS && pendingLetters) {
                        valueDescription = pendingLetters
                      }
                      switch (props.category) {
                        case TaskType.Claim:
                          value.map((item) => {
                            const findItem = letterAndTypes.find(
                              (type) => item.value !== 'SCL16' && item.value === type.value
                            )
                            if (findItem) {
                              valueDescription +=
                                (valueDescription ? '\n' : '') +
                                (checkContentDesc(findItem?.description[0]) ? findItem.lableVN : findItem?.description)
                            }
                          })
                          break
                        case TaskType.PolicyService:
                          if (casePS && pendingLetters) {
                            valueDescription = pendingLetters
                          }
                          value.map((item) => {
                            const findItem = letterAndTypes.find((type) => item.value === type.value)
                            if (findItem) {
                              findItem?.description.map((item) => {
                                valueDescription += (valueDescription ? '\n' : '') + item
                              })
                            }
                          })
                          break
                        default:
                          break
                      }
                      form.setValue('description', valueDescription)
                      onChange(value)
                    }}
                    options={
                      typeList
                      // differenceWith(
                      //   types,
                      //   formTableArray.fields.map((item) => ({
                      //     value: item.suspendCode,
                      //     label: item.suspendType
                      //   })),
                      //   isEqual
                      // ) || []
                    }
                  />
                )}
              />
            </View>
            <View style={{ width: '33.3333333%', paddingRight: 10 }}>
              <Select
                {...register('status', { required: `${t('message:MS020001', { field: t('common:Status') })}` })}
                options={statuses}
                disabled={disable}
                required
                errorMessage={(formErrors.status as FieldError | undefined)?.message || ''}
                label={t('common:Status')}
              />
            </View>
            <View style={{ width: '100%' }}>
              {allowPendingLetter && (
                <ImgUploadMutiple
                  {...pendingLetterControl}
                  value={form.getValues().pendingLetter || []}
                  maxNumFile={1}
                />
              )}
            </View>

            {casePS || checkDocCL(form.watch('group')?.value ?? '') ? (
              <View style={{ width: '100%', marginTop: 30 }}>
                <Controller
                  name="description"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020028')}`
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <View>
                      <View style={style.rowLetterContent}>
                        <View style={style.firstContent}>
                          <Text style={style.field_title}>{t('SupplementaryInfo:LetterContent')}</Text>
                          <Text style={{ color: '#ed1b2c', fontWeight: 'bold', marginStart: 5 }}>{'*'}</Text>
                        </View>
                        <View style={style.lastContent}>
                          {(checkShowButtonSuppend() || casePS) && allowEdit && (
                            <TouchableOpacity
                              style={style.buttonReview}
                              onPress={() => {
                                if (casePS) {
                                  previewLetter(false)
                                  errorForm.setValue('isPreviewed', true)
                                } else if (validPreview()) {
                                  previewLetter(false)
                                  errorForm.setValue('isPreviewed', true)
                                }
                              }}
                            >
                              {t('SupplementaryInfo:Preview')}
                            </TouchableOpacity>
                          )}
                        </View>
                      </View>

                      <TextField
                        classes={classes}
                        multiline
                        variant={'outlined'}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value)
                          errorForm.setValue('isPreviewed', false)
                        }}
                      />
                      {error?.message ? (
                        <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{error?.message}</Text>
                      ) : null}
                    </View>
                  )}
                />
              </View>
            ) : null}
          </View>

          <SC.Container>
            <SC.TabRow>
              {selected &&
                activeTabs.map(({ id, title }) => (
                  <SC.Tab
                    key={id.toString()}
                    selected={selected.id === id}
                    disabled={selected.id === id}
                    onPress={() => {
                      setSelected(activeTabs.find((tab) => tab.id === id) || null)
                    }}
                  >
                    <SC.TabText selected={selected.id === id}>{t(title)}</SC.TabText>
                  </SC.Tab>
                ))}
            </SC.TabRow>
            <SC.TabPanel selected={true}>{selected?.element()}</SC.TabPanel>
          </SC.Container>
        </View>
      </View>

      <ImageDialog selectedValueArray={image} open={openPopup} onClose={() => setOpen(false)} />
      <PopupViewSuspend
        open={openPopupViewSuspend}
        onClose={() => setOpenPopupViewSuspend(false)}
        id={supplementaryId.id}
        modifiedDate={supplementaryId.modifiedDate}
      />
      {['PS', 'CH', 'CS', 'IDC'].includes(props.category) && allowEdit ? (
        <View
          style={[style.buttonContainer, isWide ? { marginRight: 19 } : { width: '100%', justifyContent: 'center' }]}
        >
          <TouchableOpacity
            style={[sharedStyle.button, { borderColor: '#ED1B2E' }]}
            onPress={() => {
              cancel()
            }}
          >
            <Text style={sharedStyle.textButton}>{t('common:Cancel')}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[sharedStyle.button, canUpdate || canUpdateTimeout ? sharedStyle.btnDisabled : sharedStyle.btnRed]}
            disabled={canUpdate || canUpdateTimeout}
            onPress={() => {
              onSubmit()
            }}
          >
            <Text style={canUpdate || canUpdateTimeout ? sharedStyle.btnTextDisabled : sharedStyle.textButtonRed}>
              {t('SupplementaryInfo:AddSuspend')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[sharedStyle.button, !canUpdate ? sharedStyle.btnDisabled : sharedStyle.btnRed]}
            disabled={!canUpdate}
            onPress={() => {
              update()
            }}
          >
            <Text style={canUpdate ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled}>
              {t('common:Update')}
            </Text>
          </TouchableOpacity>
          {showColumnTimeout && (
            <TouchableOpacity
              style={[sharedStyle.button, !canUpdateTimeout ? sharedStyle.btnDisabled : sharedStyle.btnRed]}
              disabled={!canUpdateTimeout}
              onPress={() => {
                saveTime()
              }}
            >
              <Text style={canUpdateTimeout ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled}>
                {t('SupplementaryInfo:SaveTime')}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

const style = StyleSheet.create({
  sectionInfoHeaderTitle: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  },
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FAFAFA',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  rowLetterContent: {
    flexDirection: 'row',
    marginBottom: 10
  },
  firstContent: {
    flexDirection: 'row',
    width: '50%'
  },
  lastContent: {
    width: '50%',
    alignItems: 'flex-end'
  },
  section: {
    marginBottom: 30
  },
  currencyText: {
    fontStyle: 'italic'
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  titleSectionText: {
    fontWeight: '700',
    marginVertical: 10,
    marginTop: 30,
    textTransform: 'uppercase',
    fontSize: 16
  },
  buttonContainer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    flexDirection: 'row',
    height: 70,
    alignItems: 'center'
  },
  buttonReview: {
    fontFamily: 'BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    fontSize: '13px',
    fontWeight: 'bold',
    width: 120,
    color: '#ED1B2E',
    borderColor: '#ED1B2E',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    borderWidth: 1,
    minWidth: 120,
    height: 35,
    paddingHorizontal: 20
  }
})

const SC = {
  Container: styled(View)`
    padding: 1rem;
    background: white;
  `,
  TabRow: styled(View)`
    display: flex;
    flex-direction: row;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
  `,
  Tab: styled(Pressable) <{ selected?: boolean }>`
    color: #4f4f4f;
    border-bottom-width: ${(p) => (p.selected ? '3px' : '1px')};
    border-bottom-color: ${(p) => (p.selected ? p.theme.colors.main : '#C4C4C4')};
    padding-bottom: 9px;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
  `,
  TabText: styled(Text) <{ selected?: boolean }>`
    font-size: 15px;
    color: ${(p) => (p.selected ? 'red' : '#000')};
    font-weight: ${(p) => (p.selected ? 'bold' : 'normal')};
  `,
  TabPanel: styled(View) <{ selected?: boolean }>`
    display: ${(p) => (p.selected ? 'block' : 'none')};
    background: #ffffff;
  `
}
